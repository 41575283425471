/*
 * Show/hide navigation menu
 */

export default ((document) => {
    const toggles = document.querySelectorAll('.js-nav-toggle');
    const openClass = 'nav-open';

    function handleToggle() {
        const isExpanded = document.body.classList.contains(openClass);
        document.body.classList.toggle(openClass, !isExpanded);
    }

    // Set up event listeners
    if (toggles.length > 0) {
        for (const toggle of toggles) {
            toggle.addEventListener('click', handleToggle);
        }
    }

    document.addEventListener('keyup', (event) => {
        if (event.which === 27 && document.body.classList.contains(openClass)) {
            handleToggle();
        }
    });
})(window.document);
