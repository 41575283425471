import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes';
import 'plugins/externalLinks';
import 'modules/nav-toggle';

// Add "js" class to the html element
document.documentElement.className = document.documentElement.className.replace('no-js', 'js');

// Initialize FluidVids.js
import fluidvids from 'fluidvids';
fluidvids.init({
    selector: ['iframe', 'object'], // runs querySelectorAll()
    players: ['www.youtube.com', 'player.vimeo.com'], // players to support
});
